<template>
	<el-scrollbar class="uu-box">
		<div class="uu-comment">
			<el-tabs v-model="activeTabs" @tab-click="changeTabs">
				<el-tab-pane v-for="(item, index) in tabsOption" :key="index" :label="item.label" :name="item.value">
				</el-tab-pane>
			</el-tabs>
			<div class="uu-comment-wrap">
				<div class="uu-screen">
					<el-form :inline="true">
						<el-form-item>
							<el-input v-model="keyword" :placeholder="$t('seller.placeholder.commentKeyword')" clearable>
								<template slot="prefix">{{$t('seller.screen.search')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<div class="uu-date-picker">
								<div class="picker-prefix-label">{{$t('seller.screen.commentTime')}}</div>
								<el-date-picker v-model="datetimes" type="daterange" range-separator="-"
									:start-placeholder="$t('seller.placeholder.originDate')"
									:end-placeholder="$t('seller.placeholder.endDate')" prefix-icon=" "
									:picker-options="startPickerOptions" value-format="timestamp">
								</el-date-picker>
							</div>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="handleSearch" round>{{ $t('seller.actions.search') }}</el-button>
							<span class="clear-btn" @click="handleClear"><i class="el-icon-brush"></i>{{ $t('seller.actions.reset') }}</span>
						</el-form-item>
					</el-form>
				</div>
				<div class="uu-actions">
					<ul>
						<li>
							<span class="item-label">{{$t('seller.screen.commentType')}}</span>
							<el-radio-group v-model="evaluateGrade" size="small" @change="handleSearch">
								<el-radio-button v-for="(item,index) in gradeOptions" :key="index" :label="item.value">{{item.label}}</el-radio-button>
							</el-radio-group>
						</li>
						<li>
							<span class="item-label">{{$t('seller.screen.commentContent')}}</span>
							<el-checkbox-group v-model="evaluateContent" size="small" @change="handleSearch">
								<el-checkbox-button v-for="(item,index) in contentOptions" :key="index" :label="item.value">{{item.label}}</el-checkbox-button>
							</el-checkbox-group>
						</li>
					</ul>
				</div>
				<div class="uu-comment-list">
					<table width="100%" cellpadding="2">
						<thead>
							<tr>
								<th width="45px">
									<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
										@change="handleCheckAllChange">{{ }}</el-checkbox>
								</th>
								<th>{{$t('seller.tableHead.scoreStatus')}}</th>
								<th align="left">{{$t('seller.tableHead.commentContent')}}</th>
								<th align="left">{{$t('seller.tableHead.goodsInfo')}}</th>
								<th>{{$t('seller.tableHead.handle')}}</th>
							</tr>
						</thead>
						<template v-if="commentList.length > 0">
							<tbody v-for="(item,index) in commentList" :key="index">
								<tr class="group-header">
									<td align="center">
										<el-checkbox v-model="checkIds" :label="item.goods_evaluate_id" @change="handleCheckedChange">{{ }}</el-checkbox>
									</td>
									<td colspan="4">
										<div class="order-info">
											<div class="number">{{ $t('seller.order.number') }} {{ item.order_info.order_sn }}</div>
											<div class="buyer">
												<span>{{ item.nick_name }}</span>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td></td>
									<td>
										<div class="scoring">
											<div class="title" :class="item.description_match<3?'red':item.description_match>3?'green':'yellow'"><svg-icon icon-class="evaluate"></svg-icon> {{ item.description_match | statusFilter($t('seller.filters.commentStatus'))}}</div>
											<!-- <div class="text">信用分+1分</div> -->
										</div>
									</td>
									<td>
										<div class="goods-evaluate">
											<div class="text">{{ item.content || $t('seller.comment.writeContent') }}</div>
											<ul v-if="item.img_urls.length > 0" class="thumbs">
												<li v-for="(thumb,i) in item.img_urls" :key="i">
													<el-image :src="thumb.img_url" :preview-src-list="getThumb(item.img_urls)">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
													</el-image>
												</li>
											</ul>
											<div class="time">{{$t('seller.comment.firstTime')}}：{{ item.create_time | parseTime('{y}-{m}-{d} {h}:{i}')}}</div>
										</div>
									</td>
									<td>
										<div class="goods-info">
											<a class="name" :href="$itemBasePath+'/m/item/good/'+item.order_goods.goods_id" target="_blank">{{ item.order_goods.goods_name }}</a>
											<div class="price">￥{{ item.order_goods.price }}</div>
										</div>
									</td>
									<td></td>
								</tr>
							</tbody>
						</template>
						<tbody v-else>
							<tr>
								<td colspan="5" align="center"><span style="color: #999999;">{{$t('seller.empty.nodata')}}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="uu-foot">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next"
						:prev-text="$t('seller.pagination.prevText')" :next-text="$t('seller.pagination.nextText')"
						:total="listTotal">
					</el-pagination>
				</div>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
import { fetchCommentList } from "@/api/seller/order"
export default {
	filters: {
		statusFilter(grade,statusMap) {
			let status = 1
			if(grade<3){
				status = 0
			}else if(grade>3){
				status = 2
			}else {
				status = 1
			}
			return statusMap[status]
		}
	},
	data() {
		return {
			keyword: '',
			datetimes: '',
			startPickerOptions: {
				disabledDate: (time) => {
					return time.getTime() > Date.now();
				}
			},
			activeTabs: '0',
			tabsOption: this.$t('seller.options.commentMenu'),
			pagecurr: 1,
			pagesize: 20,
			listTotal: 0,
			commentList: [],
			checkAll: false,
			isIndeterminate: false,
			checkIds: [],
			gradeOptions: this.$t('seller.options.evaluateGrade'),
			contentOptions: this.$t('seller.options.evaluateContent'),
			evaluateGrade: '',
			evaluateContent: []
		}
	},
	created() {
		this.getCommentList()	
	},
	methods: {
		async getCommentList() {
			let loading = this.$loading()
			const params = {
				evaluation_grade: this.evaluateGrade,
				page_index: (this.pagecurr - 1) * this.pagesize,
				page_size: this.pagesize
			}
			if(this.keyword) {
				params.keyword = this.keyword
			}
			if(this.datetimes) {
				params.start_time = parseInt(this.datetimes[0]/1000)
				params.end_time = parseInt(this.datetimes[1]/1000)
			}
			if(this.evaluateContent.length>0) {
				if(this.evaluateContent.includes('1')) {
					params.is_content = 1
				}
				if(this.evaluateContent.includes('2')) {
					params.is_img = 1
				}
			}
			const { data } = await fetchCommentList(params)
			this.commentList = data.list
			this.listTotal = parseInt(data.count)
			loading.close()
		},
		changeTabs(tab, event) {
			console.log(tab, event);
		},
		handleSizeChange(val) {
			this.pagesize = val
		},
		handleCurrentChange(val) {
			this.pagecurr = val
			this.getCommentList()
		},
		handleSearch(value) {
			this.pagecurr = 1
			this.getCommentList()
		},
		handleClear() {
			this.keyword = ''
			this.datetimes = ''
			this.evaluateGrade = ''
			this.evaluateContent = []
			this.handleSearch()
		},
		handleCheckAllChange(val) {
			let ids = [];
			if(val){
				this.commentList.forEach(item=>{
					ids.push(item.goods_evaluate_id);
				})
			}
			this.checkIds = ids;
			this.isIndeterminate = false;
		},
		handleCheckedChange(value) {
			let checkedCount = this.checkIds.length;
			this.checkAll = checkedCount === this.commentList.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.commentList.length;
		},
		getThumb(thumbs) {
			const img_url = []
			for(var i=0;i<thumbs.length;i++) {
				img_url.push(thumbs[i].img_url)
			}
			return img_url
		}
	}
}
</script>

<style lang="scss" scoped>
.uu-comment {

	::v-deep .el-tabs__header {
		margin-bottom: 0;

		.el-tabs__nav-wrap {
			padding: 0 24px;
		}

		.el-tabs__nav-wrap::after {
			height: 1px;
		}
	}

	.uu-comment-wrap {
		padding: 18px 24px;

		.uu-screen {

			margin: 0 -9px;

			::v-deep .el-form-item {
				width: calc(25% - 18px);
				margin: 0px 9px 18px;

				.el-form-item__content {
					.el-select {
						width: 100%;
					}
				}
			}

			::v-deep .el-form--inline {
				.el-form-item__content {
					width: 100%;

					.el-input__inner {
						border-color: $--seller-filter-input-background;
						background-color: $--seller-filter-input-background;
					}

					.el-input__inner:focus,
					.el-input__inner:hover {
						background-color: #FFFFFF;
						border-color: $--seller-primary;
					}

					.el-input--prefix {
						.el-input__inner {
							padding-left: 72px;
						}
					}

					.uu-prefix-100 {
						.el-input--prefix {
							.el-input__inner {
								padding-left: 100px;
							}
						}
					}

					.el-date-editor.el-input__inner {
						width: 100%;
						padding-left: 52px;
					}

					.uu-date-picker {
						position: relative;

						.picker-prefix-label {
							top: 0;
							left: 10px;
							z-index: 9;
							height: 100%;
							color: #C0C4CC;
							text-align: center;
							position: absolute;
						}
					}
				}
			}

			::v-deep .el-input__prefix {
				left: 10px;
			}

			.range-number {
				line-height: 34px;
				padding: 2px 0;
				display: flex;

				.input-medium {
					flex: 1;
					width: 60px;
					height: 34px;
					line-height: 34px;
					border-radius: 4px;
					background-color: $--seller-filter-input-background;
					padding: 0 9px;
					box-sizing: border-box;
				}

				.input-medium:focus,
				.input-medium:hover {
					background-color: #FFFFFF;
				}

				.range-separate {
					padding: 0 5px;
				}
			}

			.clear-btn {
				cursor: pointer;
				color: #666666;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}

			.clear-btn:hover {
				color: $--seller-primary;
			}
		}

		.uu-action {
			margin-bottom: 15px;
		}

		::v-deep .has-gutter {

			th.el-table_1_column_1 {
				border-top-left-radius: 10px;
			}

			th.el-table_1_column_7 {
				border-top-right-radius: 10px;
			}
		}
		.uu-actions {
			li {
				display: inline-block;
				margin-right: 30px;
				margin-bottom: 16px;
				.item-label {
					margin-right: 8px;
					line-height: 28px;
				}
			}
			::v-deep .el-radio-group {
				.el-radio-button {
					margin-right: 12px;
					.el-radio-button__inner {
						border: 1px solid #f0f2f5;
						border-radius: 4px;
						background-color: #f0f2f5;
						&:hover {
							color: $--seller-primary;
							border-color: $--seller-primary;
							background-color: rgba($--seller-primary,.06);
						}
					}
					.el-radio-button__orig-radio:checked + .el-radio-button__inner {
						color: $--seller-primary;
						border-color: $--seller-primary;
						background-color: rgba($--seller-primary,.06);
						box-shadow: none;
					}
				}
			}
			::v-deep .el-checkbox-group {
				display: inline-block;
				vertical-align: middle;
				.el-checkbox-button {
					margin-right: 12px;
					.el-checkbox-button__inner {
						border: 1px solid #f0f2f5;
						border-radius: 4px;
						background-color: #f0f2f5;
						&:hover {
							color: $--seller-primary;
							border-color: $--seller-primary;
							background-color: rgba($--seller-primary,.06);
						}
					}
					&.is-checked {
						.el-checkbox-button__inner {
							color: $--seller-primary;
							border-color: $--seller-primary;
							background-color: rgba($--seller-primary,.06);
							box-shadow: none;
						}
					}
				}
			}
		}
		.uu-comment-list {

			thead:after, tbody:after {
				content: "";
				display: block;
				height: 6px;
			}

			th, td {
				padding: 12px;
				line-height: 16px;
				font-size: 12px;
			}

			th {
				border-bottom: 1px solid #f0f2f5;
			}

			.group-header {
				td {
					background-color: #f7f8fa;
				}

				td:first-child {
					border-top-left-radius: 9px;
					border-bottom-left-radius: 9px;
				}

				td:last-child {
					border-top-right-radius: 9px;
					border-bottom-right-radius: 9px;
				}
				.order-info {
					display: flex;
					font-size: 14px;
					.buyer {
						flex: 1;
						width: 0;
						text-align: right;
						padding-right: 80px;
					}
				}
			}
			.scoring {
				text-align: center;
				.title {
					&.green {
						color: #1dc11d;
					}
					&.yellow {
						color: #ff9419;
					}
					&.red {
						color: #ff3000;
					}
					.svg-icon {
						font-size: 16px;
					}
				}
				.text {
					margin-top: 4px;
				}
			}
			.goods-evaluate {
				.text {
					margin-bottom: 3px;
					word-break: break-all;
					word-wrap: break-word;
					font-size: 12px;
				}
				.time {
					color: #999999;
				}
				.thumbs {
					display: flex;
					li {
						width: 42px;
						height: 42px;
						padding: 2px;
						border: 2px solid #efefef;
						margin: 3px 8px 6px 0;
						.el-image {
							width: 100%;
							height: 100%;
						}
					}
					::v-deep .image-slot {
						color: #999999;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 24px;
					}
				}
			}
			.goods-info {
				.name {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					color: #5584ff;
				}
				.price {
					color: #ff5000;
					margin-top: 3px;
				}
			}
		}

		.uu-foot {
			padding: 15px 0;
			text-align: center;
		}
	}
}
</style>
